/* @font-face {
  font-family: 'Poppins Regular';
  src: url('../assets/fonts/Poppins-Regular.ttf');
} */
@font-face {
  font-family: 'Circular Medium';
  src: url('../assets/fonts/Circular/circular-std-medium-500.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Circular Regular';
  src: url('../assets/fonts/Circular/Circular-Book.ttf'),
    url('../assets/fonts/Circular/circular-std-medium-500.ttf') weight 500,
    url('../assets/fonts/Circular/Circular-Black.ttf') format('truetype') weight 700
}

@font-face {
  font-family: 'Circular Black';
  src: url('../assets/fonts/Circular/Circular-Black.ttf');
  font-weight: bold;
}

html,
body {
  background-color: #fff;
  font-family: 'Circular Regular';
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Circular Regular';
  overflow-x: hidden;
  /* height: 100%; */
}

#root {
  /* height: 100vh !important; */
}

/* p {
  font-size: 16px;
} */
/* 
html.has-scroll-smooth {
  overflow: hidden;
}

html.has-scroll-dragging {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
}

[data-scroll-direction='horizontal'] [data-scroll-container] {
  height: 100vh;
  display: inline-block;
  white-space: nowrap;
}

[data-scroll-direction='horizontal'] [data-scroll-section] {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  height: 100%;
}

.c-scrollbar {
  position: absolute;
  right: 0;
  top: 0;
  width: 11px;
  height: 100%;
  transform-origin: center right;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0;
}
.c-scrollbar:hover {
  transform: scaleX(1.45);
}
.c-scrollbar:hover,
.has-scroll-scrolling .c-scrollbar,
.has-scroll-dragging .c-scrollbar {
  opacity: 1;
}
[data-scroll-direction='horizontal'] .c-scrollbar {
  width: 100%;
  height: 10px;
  top: auto;
  bottom: 0;
  transform: scaleY(1);
}
[data-scroll-direction='horizontal'] .c-scrollbar:hover {
  transform: scaleY(1.3);
}

.c-scrollbar_thumb {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
  width: 7px;
  border-radius: 10px;
  margin: 2px;
  cursor: -webkit-grab;
  cursor: grab;
}
.has-scroll-dragging .c-scrollbar_thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
[data-scroll-direction='horizontal'] .c-scrollbar_thumb {
  right: auto;
  bottom: 0;
}
.has-scroll-smooth [data-scroll-container] {
  transform: perspective(1px);
} */