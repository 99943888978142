.toggle-switch {
  position: relative;
  margin-right: 10px;
  width: 200px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
}
.toggle-switch-checkbox {
  display: none;
}
.toggle-switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
  margin: 0;
}
.toggle-switch-label:focus {
  outline: none;
}
.toggle-switch-label:focus > span {
  box-shadow: 0 0 2px 5px red;
}
.toggle-switch-label > span:focus {
  outline: none;
}
.toggle-switch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.selected-toggle {
  background-color: #4c2c89;
  color: #fff;
  border-radius: 50px !important;
}
.not-selected-toggle {
  background-color: #e3deef;
  color: #a090bf;
}
.toggle {
  cursor: pointer;
  justify-content: flex-start;
  width: fit-content;
  background-color: #e3deef;
  border-radius: 50px;
}
.toggle-label-first {
  border-radius: 50px 0px 0px 50px;
  padding: 13px;
}
.toggle-label-second {
  border-radius: 0px 50px 50px 0px;
  padding: 13px;
}
.span-toggle {
  font-weight: bold;
  padding: 2rem;
}
.toggle-switch-inner:before,
.toggle-switch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 34px;
  padding: 0;
  line-height: 34px;
  font-size: 14px;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
}
.toggle-switch-inner:before {
  content: attr(data-yes);
  text-transform: uppercase;
  padding-left: 10px;
  background-color: #4c2c89;
  color: #fff;
}
.toggle-switch-disabled {
  background-color: #ddd;
  cursor: not-allowed;
}
.toggle-switch-disabled:before {
  background-color: #ddd;
  cursor: not-allowed;
}
.toggle-switch-inner:after {
  content: attr(data-no);
  text-transform: uppercase;
  padding-right: 10px;
  background-color: #e3deef;

  color: #4c2c89;
  text-align: right;
}
.toggle-switch-label {
}
.toggle-switch-switch {
  display: block;
  width: 24px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 165px;
  border: 0 solid #e3deef;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
  margin-left: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
  right: 0px;
}
.toggle-switch.small-switch {
  width: 40px;
}
.toggle-switch.small-switch .toggle-switch-inner:after,
.toggle-switch.small-switch .toggle-switch-inner:before {
  content: '';
  height: 20px;
  line-height: 20px;
}
.toggle-switch.small-switch .toggle-switch-switch {
  width: 16px;
  right: 20px;
  margin: 2px;
}
@media screen and (max-width: 991px) {
  .toggle-switch {
    transform: scale(0.9);
  }
}
@media screen and (max-width: 767px) {
  .toggle-switch {
    transform: scale(0.825);
  }
  .toggle {
    margin: auto;
  }
  .span-toggle {
    padding: 1rem 3rem;
    font-size: 1.1rem;
    font-weight: 700;
  }
  .toggle-label-first,
  .toggle-label-second {
    padding: 1rem;
  }
}
@media screen and (max-width: 575px) {
  .toggle-switch {
    transform: scale(0.75);
  }
}
