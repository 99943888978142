.background-blue {
  background-image: url('../../assets/images/background-blue.png');
  background-size: cover;
  background-position: center;
}

.button-carrosel {
  padding: 5px 8px !important;
  background-color: none;
  color: white;
  margin: 5px 15px;
  border-radius: 2px;
}

.sr-only {
  display: none;
}

.button-a-tropzz {
  padding: 5px 8px !important;
  background-color: yellow;
  color: #0398d7;
  margin: 5px 15px;
  border-radius: 2px;
}

.title-h1-nossa-equipe {
  font-family: 'Circular Black';
}

.title-h1-nossa-equipe>span {
  color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: yellow;
  font-size: 58px;
  text-shadow: none;
}

.button-content-section-a-tropzz:hover>svg>path:nth-of-type(1) {
  /* transform: scale(1.05); */
  transition: 0.5s all ease-in-out;
  fill: #ffef00 !important;
}

.button-content-section-a-tropzz:hover>svg>path:nth-of-type(2) {
  /* transform: scale(1.05); */
  transition: 0.5s all ease-in-out;
  fill: white !important;
}

.section-counter {
  height: 100vh;
  display: flex;
}

.margin-bottom-5 {
  margin-bottom: 6rem !important;
}

.carousel-control-next:hover,
.carousel-control-prev:hover {
  transform: scale(1.1);
  transition: 0.5s all ease-in-out;
}

.carousel-control-prev,
.carousel-control-next {
  width: 10% !important;
}

.awssld__next:hover,
.awssld__prev:hover {
  transform: scale(1.1);
  transition: 0.5s all ease-in-out;
}

/* .carousel-control-prev,
.carousel-control-next {
  width: 16%;
} */
.button-content-section-a-tropzz>svg>path {
  stroke: #ffef00;
}

.height-section-nossa-equipe {
  height: 47rem;
}

.title-h1-nossa-equipe {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: 'Circular Black';
}

.wave-blue {
  background: url('../../assets/images/onda-branca.png') repeat-x;

  top: -81px;
  width: 10400px;

  height: 126px;

  animation: wave 17s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.backgound-azul {
  background-color: #009cde;
}

.counter {
  font-size: 5rem;
  font-weight: bold;
  color: #009cde;
}

.counter-subtitle {
  color: #4c2c89;
  font-size: 1.5rem;
}

.background-purple {
  background-color: #4c2c89;
}

.nossa-equipe {
  position: relative;
  left: 12rem;
  font-size: 5rem;
}

.nossa-equipe>h1 {
  color: #ffef00;
  font-weight: bold;
  font-size: 4rem;
}

.background-palha {
  background-color: #fff3c1;
}

@media screen and (max-width: 900px) {
  .section-counter {
    height: auto !important;
    display: grid;
  }

  /* .carousel-control-prev,
  .carousel-control-next {
    width: 20% !important;
  } */
}