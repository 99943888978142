#circle {
  background: #009cde;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  justify-content: center;
  text-align: center;
  vertical-align: baseline;
  align-items: center;
  display: flex;
  font-size: 250%;
}
.font-weight-bold {
  font-weight: bold;
  font-size: 1rem !important;
  padding-bottom: 1rem;
}
.card-franquia {
  background-color: transparent !important;
  display: 'inline-block';
  margin: '0 ';
  color: 'white';
  font-weight: 'bold';
  user-select: 'none';
}
.pontilhada {
  /* border-style: dotted; */
  /* border-width: 1px; */
  border-color: 660033;
  background-color: cc3366;
  font-size: 10pt;
}
.react-horizontal-scrolling-menu--scroll-container {
  overflow: hidden;
}
#circle {
  color: white !important;
}
.dotted {
  border-style: dotted;
  border-width: 27px;
  border: none none 5px none !important;
  border-top-style: none;
}
.circle-passos-franquia:hover {
  background: #da0080 !important;

  color: white !important;
}
.div-card-efeito:hover {
  transform: scale(1.1) translate(5%, 10%);
  transition: 0.5s all ease-in-out;
}

.circle-passos-franquia {
  margin-right: 15rem;
}
.text-card span {
  transform: scale(1);
  transition: 0.5s all ease-in-out;
}
@media screen and (max-width: 900px) {
  .card-franquia {
    padding: 0px 6rem;
  }
  .circle-passos-franquia {
    margin-right: 0 !important;
  }
  .title-card,
  .text-card {
    padding-right: 0 !important;
  }
}
