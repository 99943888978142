.scroll-to-top {
  position: fixed;
  bottom: 0.5rem;
  left: 0.5rem;
  animation: fadeIn 700ms ease-in-out 4s both;
  cursor: pointer;
  z-index: 99999;
}
.scroll-to-top-button {
  position: fixed;
  bottom: 0rem;
  right: 0.5rem;
  animation: fadeIn 700ms ease-in-out 4s both;
  cursor: pointer;
  z-index: 99999;
}
/* .animate__pulse img {
  animation: mymove 4s infinite;
} */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes mymove {
  from {
    width: 100%;
  }
  to {
    width: 105%;
  }
}
.cupom-open-modal {
  display: -webkit-inline-box;
  background-color: white;
  position: relative;
  right: 0px;
  border-radius: 15px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  padding: 15px;
  color: #542c89;
}
.cupom-open-modal img {
  padding-right: 15px;
  padding-top: 15px;
}

.button-cupom {
  display: flex;
  justify-content: flex-start;
}
.button-cupom button {
  border: none;
  background: none;
}
