.section-3 {
  background-image: url('../../assets/images/section3.png');
  background-size: cover;
  background-position: center;
}

.title-h1-franquias {

  font-family: 'Circular Black';
}

.text-franquia-session {
  padding-right: 4rem;
}

.title-h1-franquias>strong {
  line-height: 50px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: 'Circular Black';
}

@media (max-width: 768px) {
  .about-us {
    width: 60% !important;
    margin-bottom: 15px;
    justify-content: center;
    text-align: center;
    font-size: 1.1rem;
  }

  .in-city>h1 {
    color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #44267d;
    font-size: 3.5rem;
  }

  .text-franquia-session {
    padding-right: 0rem;
    font-size: 1.4rem;
  }
}

.animation-hover:hover {
  transform: scale(1.05);
  transition: 0.5s all ease-in-out;
}