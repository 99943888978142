::marker {
  display: none !important;
}

.nav-link {
  font-weight: bold;
}

.navbar-nav {
  width: 100%;
  /* padding: 1px; */
  /* justify-content: space-between; */
}

.navbar {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  /* background-color: #4c2c89; */
  background-color: white;
}

.icons-navbar>.nav-link.active,
.icons-navbar>.nav-link,
.icons-navbar>.nav-link:hover {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.logo-navbar {
  background-color: #4c2c89;
}

.menu-items a {
  color: #4c2c89;
  text-decoration: none;
}

.navbar {
  position: fixed !important;
  top: 0 !important;
  width: 100% !important;
}

.menu-items a:hover {
  color: #4c2c89;
  text-decoration: none;
}

.backgroud-white {
  background-color: white;
}

.menu-items>div {
  justify-content: space-around;
}

.menu-items>div>div {
  width: 100%;
  text-align: center;
}

.area-franquia {
  border-radius: 20px;
  border: 2px solid #4c2c89;
  color: #4c2c89;
  padding: 5px 30px;
}

.area-franquia>span {
  padding-left: 10px;
}

.area-franqueado {
  justify-content: space-evenly;
  margin: auto;
}

.area-franqueado div {
  /* margin: auto; */
}

.social-icons {
  width: 20%;
  justify-content: center !important;
}

.social-icons div svg path {
  fill: #4c2c89 !important;
}

.social-icons>a {
  margin-right: 1.5rem;
}

.menu-items {
  height: 45px;
}

.menu-items>div {
  height: 100%;
}

.menu-items>div div {
  /* margin: auto; */
}

.menu-items ul {
  height: 100%;
}

.area-franquia>svg>path {
  fill: #4c2c89 !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #4c2c89 !important;
}

.navbar-nav {
  align-items: center;
  z-index: 9999;
}

.navbar {
  z-index: 9999;
}

.social-icons>a {
  padding: 0.5rem 0rem !important;
}

.social-icons>div>a {
  padding: 0.5rem 0rem !important;
}

.navbar-light .navbar-nav .nav-link:hover {
  background-color: #009cde;
  color: white !important;
  padding: 12px;
}

.nav-link.active {
  padding: 12px;
}

.navbar-light .navbar-nav .nav-link.active {
  background-color: #009cde;

  color: white !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #4c2c89 !important;
}

.nav-mobile {
  display: none;
}

/* .navbar {
  margin-bottom: -15px;
} */
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-item.icons-navbar {
  width: max-content;
}

.icons-navbar>.nav-link,
.icons-navbar>.nav-link.active,
.icons-navbar>.nav-link:hover {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

@media screen and (max-width: 900px) {
  .menu-items.navbar-collapse.collapse {
    height: 99vh !important;
    background-color: #4c2c89;
    color: white !important;
    font-size: 40px;
  }

  .nav-link {
    padding: 13px !important;
  }

  .car_details>div {
    width: 100%;
  }

  .car_details {
    justify-content: space-around;
    width: 100%;
  }

  .menu-items.navbar-collapse.collapsing {
    height: 0 !important;
    background-color: #4c2c89;
    color: white !important;
    font-size: 40px;
  }

  .navbar-light .navbar-nav .nav-link {
    color: white !important;
  }

  .navbar-nav {
    padding-top: 5vh;
    z-index: 9999;
    align-items: center;
  }

  .navbar {
    background-color: #4c2c89 !important;
  }

  .navbar-toggler {
    font-size: 1.5rem !important;
  }

  .navbar-light .navbar-toggler {
    border: none !important;
  }

  .navbar-light .navbar-nav .nav-link.active {
    background-color: rgba(255, 255, 255, 0) !important;
    color: #ffef00 !important;
  }

  .nav-item>.social-icons>a>div>svg>path {
    fill: white !important;
  }

  .social-icons {
    width: 100% !important;
  }

  .navbar-light .navbar-nav .nav-link:hover {
    background-color: rgba(255, 255, 255, 0) !important;
    color: #ffef00 !important;
  }

  .nav-mobile {
    display: grid;
    font-size: 5rem !important;
  }

  .nav-link {
    font-size: 2.3rem !important;
  }

  .navbar.navbar-expand-lg.navbar-light.justify-content-between.p-0.backgroud-white div {}

  .icon-navbar {
    padding: 6px;
    border: none;
  }

  .navbar-toggler:focus {
    box-shadow: 0 0 0 0rem !important;
  }
}