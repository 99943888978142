.footer {
  display: grid;
}
.icon-footer > a > svg > path {
  fill: white !important;
}
.folha-frame {
  height: 29rem !important;
}
.margin-top {
  margin-top: 6rem;
}
