.awssld {
  height: 70vh;
}

.bannercarousel {
  width: 100% !important;
  padding: 0px !important;
}

.banner-primary {
  width: 100%;
  height: auto;
  /* height: 28.822605965vw; */
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: none;
  /* border-radius: 0.25rem; */
}

.awssld.awssld--cubeAnimation {
  height: 100vh;
}

.button-content-first-slide>svg>path {
  stroke: white;
}

/* .button-content-first-slide {
  opacity: 1;
}
.button-content-first-slide:hover {
  transition: 0.5s all ease-in-out;
  opacity: 1;
} */

div.button-content-first-slide:hover svg path:nth-of-type(1) {
  stroke: #925ef4;
  fill: #925ef4 !important;
  /* transition: 0.5s all ease-in-out; */
}

div.button-content-first-slide:hover svg path:nth-of-type(2) {
  stroke: #925ef4;
  fill: #e9e0ee !important;
  /* transition: 0.5s all ease-in-out; */
}

.awssld.awssld--organic-arrows {
  height: 100vh;
}

.banner-center {
  display: block;
  margin: auto;
}

.awssld__content {
  background-color: white !important;
}

.awssld__controls__arrow-left:before,
.awssld__controls__arrow-left:after,
.awssld__controls__arrow-right:before,
.awssld__controls__arrow-right:after {
  color: white;
}

@media (min-width: 360px) and (max-width: 363px) {
  .banner-primary {
    height: auto;
    /* height: 134px !important; */
    background-size: cover;
  }
}

@media screen and (max-width: 900px) {

  .awssld__controls {
    visibility: visible !important;
  }

  .awssld__next:nth-of-type(1) {
    left: 32rem;
  }

  button.awssld__prev {
    left: 3rem !important;
  }
}

.awssld__bullets button {
  background: white !important;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2) !important;
}

.awssld__bullets .awssld__bullets--active {
  transform: scale(0.7);
}

.awssld__bullets {
  bottom: 2px;
  z-index: 999 !important;
}