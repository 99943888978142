:root {
  --color-light: white;
  --color-dark: #212121;
  --color-signal: #c6bde5;
  --color-background: var(--color-light);
  --color-text: var(--color-dark);
  --color-accent: var(--color-signal);
  --size-bezel: 0.5rem;
  --size-radius: 4px;
  line-height: 1.4;
  font-family: 'Inter', sans-serif;
  font-size: calc(0.6rem + 0.4vw);
  color: var(--color-text);
  background: var(--color-background);
  font-weight: 300;
}

.background-roxo {
  background-color: #4c2c89;
}

.p-right-5 {
  padding-right: 50px;
}

.color-white {
  color: white;
}

.section-pq-escolher-tropzz {
  display: flex;
  height: 90vh;
  align-items: center;
  align-self: center;
}

.color-pink-franquia {
  color: #df1f90;
  font-weight: bold;

  font-size: 400%;
}

.franqueado {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 43px;
  background: #df1f90;
  color: white;
  width: 80%;
  text-align: center;
  padding: 15px 40px;
}

.image-modal-confirm {
  width: 40% !important;
}

.color-purple {
  color: #4c2c89;
}

.font-15 {
  font-size: 20px;
}

.font-book {
  font-weight: 200;
}

.m-right-3 {
  margin-right: 15px;
}

.image-franquia:hover {
  transform: scale(1.1);
  transition: 0.5s all ease-in-out;
}

.p-3-right {
  padding-right: 15%;
}

.image-slider {
  align-items: center;
  align-self: center;
}

.franquias {
  align-items: center;
}

.container-franquia {

  align-items: flex-start;
  justify-content: center;
  height: fit-content;
}

.button-a-tropzz {
  background-color: #df1f90 !important;
  color: white !important;
  cursor: pointer;
}

.button-carrosel {
  cursor: pointer;
}

.background-light-green {
  background-color: #e8f8ff;
  height: 100vh;
}

svg path {
  fill: none !important;
}

div svg:hover {}

.perfil-ideal {
  height: 100vh;
}

.confirm-button-modal {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 43px;
  background: #009cde;
  width: 40%;
  text-align: center;
  padding: 15px 40px;
  cursor: pointer;
}

.confirm-button-modal>span {
  color: #fff;
  margin: 10px 2px;
}

.custom-modal-confirm {
  border-radius: 15px;
  margin: auto !important;
  display: flex;
}

.border-passos {
  border-top: 2px dashed #da0080;
  position: relative;
  top: 3rem;
  margin: 0 5rem;
}

.modal-franquia .react-responsive-modal-root {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.modal-franquia .react-responsive-modal-container {
  display: flex;
  margin: auto;
}

.passos-title {
  margin-bottom: 8rem;
}

.react-horizontal-scrolling-menu--scroll-container {
  margin: 0 1rem;
}

/* .react-horizontal-scrolling-menu--item {
  margin-right: 15rem;
} */
.arrows-passos {
  z-index: 99999;
}

.passos-ate-ser-tropzz {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: auto;
  padding: 9rem 0;
}

/* .react-responsive-modal-modal {
} */
.text-perfil-franqueado {
  color: #4c2c89;
  font-size: 14px !important;
}

.title-perfil>span {
  color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #4c2c89 !important;
}

.color-purple-franquia {
  color: #4c2c89;
  font-family: 'Circular Medium';
  font-weight: 700;
  font-size: 300%;
}

.numberCircle {
  border-radius: 50%;
  width: 66px;
  height: 66px;
  /* padding: 8px; */

  background: #df1f90;
  color: #e8f8ff;
  text-align: center;

  font: 32px Arial, sans-serif;
}

.input {
  position: relative;
  width: 100%;
}

.input__label {
  position: absolute;
  left: 0;
  top: 0;
  padding: calc(var(--size-bezel) * 0.75) calc(var(--size-bezel) * 0.5);
  margin: calc(var(--size-bezel) * 0.75 + 3px) calc(var(--size-bezel) * 0.5);
  background: pink;
  white-space: nowrap;
  transform: translate(0, 0);
  transform-origin: 0 0;
  background: var(--color-background);
  transition: transform 120ms ease-in;

  line-height: 1.2;
  color: #949494;
}

.input__field {
  box-sizing: border-box;
  display: block;
  width: 100%;
  border: 2px solid #949494;
  padding: calc(var(--size-bezel) * 1.5) var(--size-bezel);
  color: currentColor;
  background: transparent;
  border-radius: var(--size-radius);
}

.input__field:not(:-moz-placeholder-shown)+.input__label {
  transform: translate(0.25rem, -65%) scale(0.8);
  color: var(--color-accent);
  border-color: #0069d9;
}

.input__field:not(:-ms-input-placeholder)+.input__label {
  transform: translate(0.25rem, -65%) scale(0.8);
  color: var(--color-accent);
  border-color: #0069d9;
}

.input__field:focus+.input__label,
.input__field:not(:placeholder-shown)+.input__label {
  transform: translate(0.25rem, -65%) scale(0.8);
  color: var(--color-accent);
  border-color: #0069d9;
}

.card {
  background: var(--color-background);
  padding: calc(4 * var(--size-bezel));
  margin-top: calc(4 * var(--size-bezel));
  border-radius: var(--size-radius);
  border: 3px solid var(--color-shadow, currentColor);
  box-shadow: 0.5rem 0.5rem 0 var(--color-shadow, currentColor);
}

.card--inverted {
  --color-background: var(--color-dark);
  color: var(--color-light);
  --color-shadow: var(--color-accent);
  border-color: #0069d9;
}

.card--accent {
  --color-background: var(--color-signal);
  --color-accent: var(--color-light);
  border-color: #0069d9;
  /* color: var(--color-dark); */
}

.card *:first-child {
  margin-top: 0;
}

.input__field:focus {
  border-color: #0069d9;
  z-index: 1;
}

.input__field:focus {
  outline: none !important;
  border: 2px solid #0069d9;
  z-index: 1;
}

#card-address>h3 {
  font-size: 2.2rem !important;
}

#card-address>p {
  font-size: 1rem !important;
}

@media screen and (max-width: 900px) {
  .section-pq-escolher-tropzz {
    height: auto !important;
  }

  .text-align-center-franquia {
    text-align: center;
  }

  #card-address>h3 {
    font-size: 2.2rem !important;
  }

  #card-address:first-child {
    font-size: 1.2rem !important;
  }

  .text-nosso-paraiso {
    font-size: 1.5rem;
  }

  .p-3-right {
    padding: 0;
  }

  .icon-number-franquia {
    color: #df1f90;
    opacity: 0.5;
    font-size: 60px !important;
  }

  .text-slide {
    padding: 20px;
  }

  .mochila {
    display: none;
  }

  .newsletter_input {
    width: 80%;
  }

  .button-franqueado {
    width: 100%;
  }

  .franquias {
    height: auto !important;
  }

  .icon-number-franquia {
    font-family: 'Circular Black';
  }

  .text-align-center-franquia p {
    font-family: 'Circular Medium';
    font-size: 1.4rem;
  }

  .p-3-right {
    padding: 0 25%;
  }

  .background-light-green {
    height: auto !important;
  }

  .subtitle-perfil {
    font-size: 1.2rem;
  }
}

.font-medium {
  font-family: 'Circular Medium';
}

.button-franqueado {
  font-family: 'Circular Black';
}

div>h6 {
  font-weight: bold;
}