#card {
  --color-light: white;
  --color-dark: #4c2c89;
  --color-signal: #4c2c89;
  --color-background: var(--color-light);
  --color-text: var(--color-dark);
  --color-accent: var(--color-signal);
  --size-bezel: 0.5rem;
  --size-radius: 4px;
  line-height: 1.4;
  font-family: 'Circular Medium';
  font-size: calc(0.6rem + 0.4vw);
  color: var(--color-text);
  background: var(--color-background);
  font-weight: 300;
  padding: 0 calc(var(--size-bezel) * 3);
}

#card-address {
  --color-light: white;
  --color-dark: #4c2c89;
  --color-signal: #4c2c89;
  --color-background: var(--color-light);
  --color-text: var(--color-dark);
  --color-accent: var(--color-signal);
  --size-bezel: 0.5rem;
  --size-radius: 4px;
  line-height: 1.4;
  font-family: 'Circular Medium';
  font-size: calc(0.6rem + 0.4vw);
  color: var(--color-text);
  background: var(--color-background);
  font-weight: 300;
  padding: 0 calc(var(--size-bezel) * 3);
}

.icon-social>a>svg>path {
  fill: white !important;
}

h1,
h2,
h3 {
  font-weight: 900;
}

mark {
  background: var(--color-accent);
  color: var(--color-text);
  font-weight: bold;
  padding: 0 0.2em;
}

.input__name {
  margin-bottom: 5px;
}

.input__name2 {
  margin-bottom: 25px;
}

#card {
  background: var(--color-background);
  padding: calc(4 * var(--size-bezel));
  margin-top: calc(4 * var(--size-bezel));
  border-radius: var(--size-radius);
  border: none !important;
  filter: drop-shadow(6px 7px 17px rgba(0, 0, 0, 0.14));
}

.card--inverted {
  --color-background: var(--color-dark);
  color: var(--color-light);
  --color-shadow: var(--color-accent);
}

.card--accent {
  --color-background: var(--color-signal);
  --color-accent: var(--color-light);
  color: var(--color-dark);
}

.card *:first-child {
  margin-top: 0;
}

#card-address {
  background: var(--color-background);
  padding: calc(4 * var(--size-bezel));
  margin-top: calc(4 * var(--size-bezel));
  border-radius: var(--size-radius);
  border: none !important;
  filter: drop-shadow(6px 7px 17px rgba(0, 0, 0, 0.14));
}

.card-address *:first-child {
  margin-top: 0;
}

.l-design-widht {
  max-width: 40rem;
  padding: 1rem;
}

.input {
  position: relative;
}

.input__label {
  position: absolute;
  left: 0;
  top: 0;
  padding: calc(var(--size-bezel) * 0.75) calc(var(--size-bezel) * 0.5);
  margin: calc(var(--size-bezel) * 0.75 + 3px) calc(var(--size-bezel) * 0.5);
  background: pink;
  white-space: nowrap;
  transform: translate(0, 0);
  transform-origin: 0 0;
  background: var(--color-background);
  transition: transform 120ms ease-in;
  /* font-weight: bold; */
  line-height: 1.2;
}

.input__field {
  box-sizing: border-box;
  display: block;
  width: 100%;
  border: 2px solid var(--color-signal);
  padding: calc(var(--size-bezel) * 1.5) var(--size-bezel);
  color: var(--color-signal);
  background: transparent;
  border-radius: var(--size-radius);
}

.input__field:not(:-moz-placeholder-shown)+.input__label {
  transform: translate(0.25rem, -65%) scale(0.8);
  color: var(--color-accent);
}

.card-div {
  margin-left: 8rem;
}

.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

/* .css-14dclt2-Input > input {
  display: none !important;
} */
.input__field:not(:-ms-input-placeholder)+.input__label {
  transform: translate(0.25rem, -65%) scale(0.8);
  color: var(--color-accent);
}

.input__field:focus+.input__label,
.input__field:not(:placeholder-shown)+.input__label {
  transform: translate(0.25rem, -65%) scale(0.8);
  color: var(--color-accent);
}

.input__field:focus+.input__field:focus-visible {
  border: 2px solid var(--color-signal) !important;
}

.input__field:focus-visible {
  border: 2px solid var(--color-signal) !important;
  color: var(--color-signal);
}

.animation-hover-instagram:hover {
  transform: scale(1.5);
  transition: 0.5s all ease-in-out;
}

.images-instagram {
  margin: 1px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 4px;
  position: relative;
  top: 3rem;
}

.images-instagram>img {
  width: 100%;
}

.no-text-decoration {
  padding-left: 10px;
  text-decoration: none;
  color: white;
}

.no-text-decoration:hover {
  text-decoration: none;
  color: white;
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
}

.hidden {
  display: none;
}

.car_details {
  background-color: #4c2c89;
  color: white;
}

.schedule p {
  margin: 0;
}

.schedule {
  justify-content: center;
}

svg path {
  fill: white;
}

@media screen and (max-width: 900px) {
  #card-address {
    background: none;
    padding: 1rem;
  }

  #card-div {
    padding: 0px !important;
  }

  .image-loja {
    padding-top: 3rem;
  }

  .car_details {
    padding: 4rem 2rem !important;
  }

  .wave-onde-ce-mora {
    display: none;
  }

  .css-1s2u09g-control {
    background: none !important;
  }

  .address {
    padding-bottom: 0px;
  }
}