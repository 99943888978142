/* .cupons {
  height: 100vh;
} */

.button-cupom {
  display: flex;
  justify-content: flex-end;
}

.p-t-8 {
  padding-top: 8%;
}

.multi-item-carousel .carousel-inner>.item {
  transition: 500ms ease-in-out left;
}

.multi-item-carousel .carousel-inner .active.left {
  left: -33%;
}

.multi-item-carousel .carousel-inner .active.right {
  left: 33%;
}

.multi-item-carousel .carousel-inner .next {
  left: 33%;
}

.multi-item-carousel .carousel-inner .prev {
  left: -33%;
}

@media all and (transform-3d),
(-webkit-transform-3d) {
  .multi-item-carousel .carousel-inner>.item {
    transition: 500ms ease-in-out left;
    transition: 500ms ease-in-out all;
    backface-visibility: visible;
    transform: none !important;
  }
}

.backgroud-purple {
  background-color: #4c2c89;
  color: yellow;
  width: 50%;
  font-size: 30px !important;
  text-align: center;
  padding: 5px;
}

svg>path {
  fill: #4c2c89 !important;
}

/* .react-responsive-modal-modal {

  background: yellow !important;
} */
.multi-item-carousel .carouse-control.left,
.multi-item-carousel .carouse-control.right {
  background-image: none;
}

.voucher-container {
  background-color: yellow;
  border-radius: 10px;
  color: #4c2c89;
  font-weight: bold;
  height: 100%;
}

.image-cupom {
  position: relative;
  bottom: 40px;
  z-index: 1;
}

h3 {
  font-weight: bold !important;
}

.p-right-10 {
  padding-right: 50px;
}

.voucher-container-each {

  height: 90%;
  cursor: pointer;
}

.backgroud-blue {
  background-color: #009cde;
  color: #fff;
  padding: 8px;
  min-width: 32%;
  font-size: 18px;
  border-radius: 5px;
  box-shadow: 1px 4px 5px 1px rgb(0 0 0 / 20%);
  position: absolute;
  left: 9px;
  text-align: center;
}

.backgroud-blue span {
  font-weight: bold;
  text-align: center;
}

.color-yellow {
  color: yellow;
  font-weight: bold;
  font-size: 3.5rem;
  font-family: 'Circular Medium';
}

.benefit-description {
  font-size: 18px;
  font-weight: 200;
  padding-bottom: 1rem;
  /* white-space: nowrap; */
  /* Impede a quebra de linha */
  overflow: hidden;
  /* Oculta qualquer conteúdo que ultrapasse a largura */
  text-overflow: ellipsis;
}

.cupons {
  height: 100% !important;
}

.height-100vh {
  height: 100vh !important;
}

.modal-cupons {
  height: auto !important;
  background-color: yellow !important;
  border-radius: 10px;
}

.react-responsive-modal-root,
.react-responsive-modal-container {
  z-index: 9999 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-cupons .react-responsive-modal-closeButton>svg>path {
  fill: #4c2c89 !important;
}

@media (max-width: 768px) {
  .voucher-container-each {

    height: 90%;
    cursor: pointer;
  }
}