.color-pink {
  color: #da0080;
  font-family: 'Circular Medium';
  font-weight: 700;
  font-size: 400%;
}

.section-1 {
  background-image: url('../../assets/images/section1.png');
  background-size: cover;
  background-position: center;
}

.background-folha {
  background-image: url('../../assets/images/folha.png');
  background-size: cover;
  background-position: center;
}

.letter-spacing {
  letter-spacing: 3px;
}

.letter-spacing-navbar {
  letter-spacing: 1px;
}

.title-h1 {
  line-height: 4rem;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: 'Circular Black';
}

.title-h1 span {
  color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #da0080;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0);
}

.about-us>span {
  font-family: 'Circular Black';
}

.folha {
  position: absolute;
  top: -150px;
  margin-left: 25%;
}

.tropical p {
  color: #44267d;
  width: 80%;
  font-size: 1.2rem;
  margin: 2rem 0;
}

.button-carrosel:hover {
  background-color: #df1f90 !important;
}

.image-carousel-home:hover {
  transform: scale(1.1);
  transition: 0.5s all ease-in-out;
}

.about-us {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 43px;
  background: #4b2b87;
  width: 40%;
  text-align: center;
  padding: 15px 40px;
  cursor: pointer;
}

.about-us>span {
  color: #fff;
  margin: 10px 2px;
}

.ocean {
  height: 6%;
  width: 100%;

  overflow: hidden;
}

.background-purple {
  background-image: url('../../assets/images/background-purple.png');
  background-size: cover;
  background-position: center;
}

.white {
  color: #fff;
}

.padding-right-3 {
  padding-right: 1rem;
  margin-right: 5rem;
}

div>a {
  text-decoration: none;
  color: #fff;
}

input::placeholder {
  color: #fff;
}

.newsletter {
  background-color: #009cde;
  padding: 10px;
  border-radius: 0px 8px 8px 0px;
  border-color: #009cde;
}

.newsletter_input {
  background-color: #44267d;
  border-color: #009cde;
  color: #fff;
}

input:focus-visible {
  border-color: #44267d;
  margin: 0px !important;
}

div>a:hover {
  text-decoration: none;
  color: #fff;
}

.wave {
  background: url('../../assets/svg/onda.svg') repeat-x;

  top: -81px;
  width: 10400px;
  height: 28px;
  animation: wave 17s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  /* transform: translate3d(0, 0, 0); */
}

.wave-bege {
  background: url('../../assets/svg/onda-bege.svg') repeat-x;
  background-color: #44267d;
  top: -71px;
  width: 10400px;
  height: 28px;
  animation: wave 17s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  /* transform: translate3d(0, 0, 0); */
}

.wave-white {
  background: url('../../assets/svg/onda-branca.svg') repeat-x;

  top: -81px;
  width: 10400px;
  height: 28px;
  animation: wave 17s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  /* transform: translate3d(0, 0, 0); */
}

.wave:nth-of-type(2) {
  top: -175px;
  animation: wave 17s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
    swell 7s ease -1.25s infinite;
  opacity: 1;
}

.wave-white:nth-of-type(2) {
  top: -175px;
  animation: wave 17s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
    swell 7s ease -1.25s infinite;
  opacity: 1;
}

.wrapper {
  overflow: hidden;
  max-width: 100%;
}

.marquee {
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  animation: marquee 7s linear infinite;
}

.marquee h1 {
  display: inline-block;
  font-size: 150px;
}

.tropicalidades {
  color: transparent;
  -webkit-text-stroke-width: 0.2mm;
  -webkit-text-stroke-color: #ffef00;
}

.section-2 {
  background-color: #4c2c89;
}

.estrelas {
  position: relative !important;
  width: 92% !important;
  height: max-content;
  left: -42px;
}

.image-slider {
  padding: 0px 3.5rem;
}

.tex-slide-tropicalidades {
  padding-right: 3.5rem;
}

.text-slide {
  color: #fff;
  font-size: 1.2rem;
}

.text-slide>div:first {
  font-weight: 900;
}

.text-slide>h1 {
  color: #ffef00;
  font-size: 350%;
  font-weight: 900;
  font-family: 'Circular Black';
}

.section-3 {
  background-image: url('../../assets/images/section3.png');
  background-size: cover;
  background-position: center;
  /* height: 80vh; */
  display: flex;
  margin: auto;
  /* align-items: center; */
}

.segue-noix {
  background-image: url('../../assets/images/section3.png');
  background-size: cover;
  background-position: center;
  /* height: 80vh; */
  display: flex;
  margin: auto;
  /* align-items: center; */
}

.insta {
  background-image: url('../../assets/images/insta.png');
  background-size: cover;
  background-position: center;
  height: 800px;
}

.blog>h1 {
  color: transparent;
  font-size: 58px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffef00;
}

.blog>h1>strong {
  color: #ffef00;
  -webkit-text-stroke-width: 0px;
}

.section-4 {
  background-color: #44267d;
}

.section-4>.container {
  padding: 5rem;
}

.blog-notice {
  padding: 0 20px;
  color: #fff;
}

.text-blog p {
  color: #fff !important;
}

.section-5 {
  background: #e8f8ff;
}

.chega-mais {
  color: transparent;
  font-size: 13rem !important;
  /* -webkit-text-stroke-width: 1px; */
  -webkit-text-stroke-width: 0.2mm;
  -webkit-text-stroke-color: #009cde;
}

.segue-insta>h1 {
  font-weight: 900;
  font-family: 'Circular Black';
  font-size: 68px;
  line-height: 86px;
  color: #db1d8d;
}

.icon-social-contatinho>a>svg>path {
  fill: #44267d !important;
}

.folha-frame>img {
  position: relative;
  bottom: 5rem;
  left: 15rem;
  max-width: 500px;
}

.text-blog {
  height: 100%;
}

.title-text-blog {
  font-size: 24px;
}

.contatinho {
  padding: 10rem 0;
  /* align-items: center; */
}

.form-margin-static {
  margin: 30vh 0 0 0 !important;
}

.font-size-20 {
  font-size: 1.2rem !important;
}

.section-3>.container:first-child {
  padding: 10rem 3rem;
  color: #44267d;
}

.segue-noix {
  padding: 0px 5rem !important;
}

.height-100vh {
  height: 80vh;
}

.address {
  background-image: url('../../assets/images/loja.png');
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  background-color: #e8f8ff;
  padding: 3rem 0px;
  padding-bottom: 5rem;
  /* height: 85vh; */
}

.section-1>.container:first-child {
  padding: 9rem 5rem;
  align-items: center;
  display: flex;
  height: auto;
}

.css-qc6sy-singleValue {
  color: #44267d !important;
}

.video>img {
  width: 100%;
}

.css-14dclt2-Input {
  color: #44267d !important;
}

.css-1s2u09g-control {
  border-color: #44267d !important;
}

.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

.in-city {
  padding-right: 3rem;
}

.in-city>h1 {
  color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #44267d;
  font-size: 58px;
}

.in-city>h1>strong {
  color: #44267d !important;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #44267d;
}

.contact {
  width: 50%;
  font-size: 16px;
  line-height: 20px;
  color: #4c2c89;
}

.title-contact {
  font-size: 68px;
  line-height: 86px;
  color: #da0080;
  font-weight: 600;
}

@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

@keyframes wave {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: -1600px;
  }
}

.image-coqueiro {
  position: absolute;
  width: 26%;
}

@keyframes swell {

  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }

  50% {
    transform: translate3d(0, 5px, 0);
  }
}

.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

.content-cookie-acept {
  display: flex;
  flex-direction: column;
  margin: 15px 0 0;
}

h1.title-h1.color-pink {
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #da0080;
}

@media screen and (min-width: 2560px) {
  .address {
    padding-bottom: 5rem;
    height: auto !important;
  }
}

@media screen and (min-width: 1440px) {
  .address {
    padding-bottom: 5rem;
    height: auto !important;
  }
}

@media screen and (min-width: 1024px) {
  .address {
    padding-bottom: 3rem;
    min-height: 90vh !important;
  }
}

.css-1okebmr-indicatorSeparator {
  display: none !important;
}

.tropicalidades {
  font-family: 'Circular Black';
}

@media screen and (max-width: 900px) {

  .footer,
  .contact {
    padding-top: 3rem;
  }

  .segue-noix {
    padding: 0px 1rem !important;
  }

  .height-section-nossa-equipe {
    height: auto !important;
  }

  .icon-social-contatinho {
    padding-right: 1rem !important;
  }

  .tropical p {
    width: 100%;
  }

  p,
  a,
  input {
    font-size: 1.3rem;
  }

  .newsletter_input {
    font-size: 1.3rem;
    padding: 1rem;
  }

  .icon-footer {
    padding-right: 1rem !important;
  }

  .newsletter {
    display: flex;
    border-radius: 0px 2px 2px 0px !important;
  }

  .newsletter>span {
    font-size: 1.3rem;
    align-self: center;
  }

  .nossa-equipe {
    /* padding: 1rem; */
    /* position: relative; */
    left: 0rem !important;
  }

  .title-h1-nossa-equipe {
    padding: 1rem;
    padding-bottom: 7rem;
    font-size: 1rem;
  }

  .title-h1-nossa-equipe>span {
    font-size: 3.5rem !important;
  }

  .card-div {
    margin-left: 0px !important;
  }

  .contatinho {
    height: auto;
  }

  .form-margin-static {
    margin: auto !important;
  }

  .address {
    height: auto !important;
    background: #e8f8ff;
  }

  .awssld.awssld--organic-arrows {
    max-height: 27vh;
  }

  .section-1>.container:first-child {
    padding: 2rem !important;
  }

  .marquee h1 {
    font-size: 50px;
  }

  .in-city>h1 {
    font-size: 3rem;
  }

  .section-3>.container:first-child {
    padding: 2rem;
  }

  .segue-insta>h1 {
    font-weight: 900;
    padding-left: 10px;
    font-size: 3rem;
    line-height: 3rem;
  }

  .img-grid {
    margin: 1px auto;
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    grid-gap: 1px;
  }

  .chega-mais {
    font-size: 80px !important;
  }

  .marquee {
    animation: marquee 7s linear infinite;
  }
}

.awssld__controls button .awssld__controls__arrow-left,
.awssld__controls button .awssld__controls__arrow-right {
  opacity: 1 !important;
}

.awssld__controls__arrow-right::before,
.awssld__controls__arrow-left::before {
  background-color: white !important;
}

.awssld__controls__arrow-right::after,
.awssld__controls__arrow-left::after {
  background-color: white !important;
}

.wave-onde-ce-mora {
  position: relative;
  top: -25px;
}

.animation-hover-section-instagram:hover {
  transform: scale(1.1);
  transition: 0.5s all ease-in-out;
  z-index: 999;
}

.button-content-tropicalidades:hover {
  opacity: 1 !important;
  transform: scale(1.1);
  transition: 0.5s all ease-in-out;
}

div.button-content-tropicalidades:hover svg path:nth-of-type(1) {
  stroke: #925ef4;
  fill: #925ef4 !important;
  /* transition: 0.5s all ease-in-out; */
}

div.button-content-tropicalidades:hover svg path:nth-of-type(2) {
  stroke: #925ef4;
  fill: #e9e0ee !important;
  /* transition: 0.5s all ease-in-out; */
}

/* image grid styles */
.img-grid {
  margin: 1px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1px;
}

.img-wrap {
  /* overflow: hidden; */
  height: 0;
  padding: 50% 0;
  /* padding controls height, will always be perfectly square regardless of width */
  position: relative;
  opacity: 1;
}

.img-wrap img {
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;

  position: absolute;
  top: 0;
  left: 0;
}

/* modal styles */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  opacity: 1 !important;
}

.backdrop img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  border: 3px solid white;
  transform: none !important;
}